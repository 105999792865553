import React, { useEffect, useState,useRef } from 'react';
import { Form, Table, Col, Button, Row, Tab, Nav, Badge, Modal } from "react-bootstrap";
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BACKEND_DOMAIN } from '../../../config/variables';
import { GET, PATCH, POST } from '../../../utils/axios.util';
import PAGINATION from '../../../components/common/pagination';

import rd_trash from '../../../assets/images/icons/rd_trash.png';
import mode_edit from '../../../assets/images/icons/mode_edit.png';
import io from "socket.io-client";
import moment from 'moment';
const socket = io(BACKEND_DOMAIN); // Change to your backend URL
function Tickets() {
    const chatBoxRef = useRef(null);
    const socketRef = useRef(null);


    const [isConnected, setIsConnected] = useState(socket.connected);

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()

    const itemsPerPage = 10
    const [pendingOffset, setPendingOffset] = useState(0);
    const [progressOffset, setProgressOffset] = useState(0);
    const [resolvedOffset, setResolvedOffset] = useState(0);
    const [pendingPageCount, setPendingPageCount] = useState(0);
    const [progressPageCount, setProgressPageCount] = useState(0);
    const [resolvedPageCount, setResolvedPageCount] = useState(0);
    const [pendingItems, setPendingItems] = useState([])
    const [progressItems, setProgressItems] = useState([])
    const [resolvedItems, setResolvedItems] = useState([])
    const [activePageNumber, setActivePageNumber] = useState('');

    const [tickets, setTickets] = useState({})
    const [ticketId, setTicketId] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isNoRecords, setIsNoRecords] = useState(false)
    const [replyMessage, setReplyMessage] = useState(false)
    const [moreInfoMessage, setMoreInfoMessage] = useState(false)
    const [modalText, setModalText] = useState('')
    const [modalType, setModalType] = useState('')
    const handleTicketModalClose = () => setTicketModalShow(false);
    const handleDeleteModalClose = () => setDeleteModalShow(false);
    const handleMessageModalClose = () => setMessageModalShow(false);
    const [ticketModalShow, setTicketModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [messageModalShow, setMessageModalShow] = useState(false);
    console.log("herererer",isNoRecords,isLoading,tickets)
    /* Maintain tab state code starts here */
    const [messages, setMessages] = useState([]);
    useEffect(() => {
        if (chatBoxRef.current) {
          chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
      }, [messages]);
    
    useEffect(() => {
        if (!socketRef.current) {
          socketRef.current = io(BACKEND_DOMAIN);
        }
      
        const socket = socketRef.current;
      
        if (ticketId) {
          socket.emit("join_room", ticketId);
      
          // ✅ Prevent duplicate listeners
          socket.off("receive_message").on("receive_message", (data) => {
            console.log("📩 New Message:", data);
            setMessages((prev) => [...prev, data]);
          });
        }
      
        return () => {
          // ✅ Only disconnect when component unmounts
          if (!ticketId) {
            socket.disconnect();
            socketRef.current = null;
          }
        };
      }, [ticketId]);
      
    const urlParams = new URLSearchParams(window.location.search);
    let defaultTabValue = 'pending'
    let currentPageNumValue = 0
    let initialPageValue = 0

    var urlTab = urlParams.get('tab')
    var urlPage = urlParams.get('page')

    if (urlTab == 'progress') {
        defaultTabValue = 'progress'
    }

    if (urlTab == 'resolved') {
        defaultTabValue = 'resolved'
    }

    if (urlPage) {
        currentPageNumValue = Number(urlPage) - 1
        initialPageValue = Number(urlPage) - 1
    }

    const [currentPageNum, setCurrentPageNum] = useState(currentPageNumValue);
    const initialPageNum = initialPageValue;

    // var initialPageNum = initialPageValueRef.current
    const [defaultTab, setDefaultTab] = useState(defaultTabValue);
    const [currentTabState, setCurrentTabState] = useState(defaultTabValue);

    /* Maintain tab state code ends here */

    useEffect(() => {

        if (Object.keys(tickets).length === 0) {
            getTickets()
        }

    }, [tickets]);

    const getTickets = () => {

        setIsLoading(true)
        let payload = {
            url: `${BACKEND_DOMAIN}/admin/support/tickets`
        }

        GET(payload).then(res => {
            if (res.data) {
                const { data } = res.data

                console.log(data)

                const pendingEndOffset = pendingOffset + itemsPerPage;
                const progressEndOffset = progressOffset + itemsPerPage;
                const resolvedEndOffset = resolvedOffset + itemsPerPage;
                let pendingChunk = []
                let progressChunk = []
                let resolvedChunk = []
                let pendingPageCount = Math.ceil(data.pending.length / itemsPerPage);
                let progressPageCount = Math.ceil(data.progress.length / itemsPerPage);
                let resolvedPageCount = Math.ceil(data.resolved.length / itemsPerPage);

                if (data.pending.length > 0) {
                    pendingChunk = data.pending.slice(pendingOffset, pendingEndOffset)
                }
                if (data.progress.length > 0) {
                    progressChunk = data.progress.slice(progressOffset, progressEndOffset)
                }
                if (data.resolved.length > 0) {
                    resolvedChunk = data.resolved.slice(resolvedOffset, resolvedEndOffset)
                }

                setIsNoRecords(false)
                setIsLoading(false)

                setPendingPageCount(pendingPageCount)
                setProgressPageCount(progressPageCount)
                setResolvedPageCount(resolvedPageCount)
                setPendingItems(pendingChunk)
                setProgressItems(progressChunk)
                setResolvedItems(resolvedChunk)

                setTickets(data);
                setIsLoading(false)
                setIsNoRecords(false)
            }
        }).catch(err => {
            console.log(err)

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if (err.response.status === 404) {
                setIsLoading(false)
                setIsNoRecords(true)
            }
        })
    }

    const changeStatus = (data) => {
        let URL = `${BACKEND_DOMAIN}/admin/support/tickets/resolve`
        let statusType = ''
        // if (moreInfoMessage) {
        if (data == 'more') {
            data = {
                message : "I need some more information for this issue."
            }
            statusType ='more'
            // URL = `${BACKEND_DOMAIN}/admin/support/tickets/more-info`
        }

        // if (modalType == 'close') {
        if (data == 'resolved') {
            statusType = 'resolved';
            data = {
                message : "Your issue has been resolved. Thank you!"
            }
            URL = `${BACKEND_DOMAIN}/admin/support/tickets/close`
        }

        // setIsLoading(true)
        let payload = {
            url: URL,
            data: {
                ticket_id: ticketId,
                message: data.message
            }
        }

        PATCH(payload).then(res => {
            console.log("res",res)
            const newMessage = { message: data.message, message_by: "admin" };
            // setMessages((prevMessages) => [...prevMessages, newMessage]); // Add message to state
        
            // socket.emit("send_message", ticketId);
            socket.emit("send_message", { message: data.message, room_id: ticketId , message_by: "admin"});
    
             // Send message to server
  
            reset();
            if (modalType == 'close') {
            setMessageModalShow(false)
            toast.success(res.data.message)
            // getTickets()
            }
            if (statusType == 'resolved') {
                statusType = ''
                setMessageModalShow(false)
                setTicketModalShow(false)
                toast.success(res.data.message)
                // getTickets()
            }
            // setMessageModalShow(false)
            // toast.success(res.data.message)
            setIsLoading(false)
            getTickets()
        }).catch(err => {

            setMessageModalShow(false)
            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            console.log(err)
        })
    }

    const deleteTicket = () => {

        let ticket_id = ticketId

        let data = {
            ticket_id
        }

        let payload = {
            url: `${BACKEND_DOMAIN}/admin/support/tickets/delete`,
            data
        }

        POST(payload).then(res => {
            setDeleteModalShow(false)
            toast.success(res.data.message)
            getTickets()
        }).catch(err => {

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setDeleteModalShow(false)
            toast.error(err.response.data.message)
        })

    }

    const changeTab = (tab) => {
        setSearchParams({ tab });
        setCurrentPageNum(0)
        setCurrentTabState(tab)
    }

    const openTicketModal = (ticket_id, type) => {
        setTicketModalShow(true)
        setTicketId(ticket_id)
        if (type == 'resolve') {
            setModalText('Are you sure you want to reply and move this ticket to progress ?')
            setModalType('resolve')
        } else {
            setModalText('Are you sure you want to close this ticket ?')
            setModalType('close')
        }
    }


    const openMessageModal = (ticket_id, type) => {
        // reset()
        console.log("modalType",modalType)
        if(modalType === 'close'){

        }
        if (type == 'reply') {
            if(ticket_id){
                setTicketId(ticket_id)
            }
            setReplyMessage(true)
            setMoreInfoMessage(false)
        } else {
            setTicketId(ticket_id)
            setReplyMessage(false)
            setMoreInfoMessage(true)
        }
        setTicketModalShow(false)
        setMessageModalShow(true)
    }
    useEffect (() =>{
        if(ticketId){
            getMessageList();
        }
    },[messageModalShow])
 const getMessageList = () => {

        // setIsLoading(true)
        let payload = {
            url: `${BACKEND_DOMAIN}/admin/support/message/list`,
            data: {
                ticket_id: ticketId,
            }
        }

        PATCH(payload).then(res => {
            if(res.data.status === 'success'){
                setMessages(res.data.data); // Add message to state
            }

            console.log("HERE RES",res)
        }).catch(err => {

            setMessageModalShow(false)
            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            console.log(err)
        })
        
    }
    const openDeleteModal = (ticket_id) => {
        setTicketId(ticket_id)
        setDeleteModalShow(true)
    }

    const handlePageClick = (event, currentTab) => {

        let items = []

        if (currentTab == 'pending') {
            items = tickets.pending
        }

        if (currentTab == 'progress') {
            items = tickets.progress
        }

        if (currentTab == 'resolved') {
            items = tickets.resolved
        }

        const newOffset = (event.selected * itemsPerPage) % items.length;

        const endOffset = newOffset + itemsPerPage;
        let chunk = items.slice(newOffset, endOffset)
        const pageCount = Math.ceil(items.length / itemsPerPage);

        if (currentTab == 'pending') {
            setPendingPageCount(pageCount)
            setPendingItems(chunk)
            setPendingOffset(newOffset);
        }
        if (currentTab == 'progress') {
            setProgressPageCount(pageCount)
            setProgressItems(chunk)
            setProgressOffset(newOffset);
        }
        if (currentTab == 'resolved') {
            setResolvedPageCount(pageCount)
            setResolvedItems(chunk)
            setResolvedOffset(newOffset);
        }

    };

    return (
        <>

            <h2 className="page_hdng">Tickets</h2>
            <Row className="mt-3">
                <Col sm="12" md="6" lg="2">
                    <div className="assignments_card">
                        <h4>{Object.keys(tickets).length > 0 ? tickets.pending.length : 0}</h4>
                        <p className='text-capatilize'>pending</p>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="2">
                    <div className="assignments_card">
                        <h4>{Object.keys(tickets).length > 0 ? tickets.progress.length : 0}</h4>
                        <p className='text-capatilize'>in progress</p>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="2">
                    <div className="assignments_card">
                        <h4>{Object.keys(tickets).length > 0 ? tickets.resolved.length : 0}</h4>
                        <p className='text-capatilize'>resolved</p>
                    </div>
                </Col>
                

               
            </Row>

            <Row>
                <Col>
                    <div className="fixed_tab">
                        <Tab.Container id="fdgdfg" defaultActiveKey={defaultTab}>
                            <Row className="align-items-center justify-content-between">
                                <Col className='col-auto'>
                                    <Nav variant="fixed_pills">
                                        <Nav.Item>
                                            <Nav.Link eventKey="pending" onClick={() => changeTab('pending')}>Pending</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="progress" onClick={() => changeTab('progress')}>In Progress</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="resolved" onClick={() => changeTab('resolved')}>Resolved</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                {/* <Col className='col-auto'>
                                    <Col className='col-auto'>
                                        <div className='d-flex justify-content-end'>
                                            <Button variant='success' size='md' className='px-4 ms-2' onClick={() => navigate(`/support/new-ticket`)} >New Ticket</Button>
                                        </div>
                                    </Col>
                                </Col> */}
                            </Row>

                            <Tab.Content>
                                <Tab.Pane eventKey="pending">
                                    <Table className="">
                                        <thead>
                                            <tr>
                                                <th>Support ID</th>
                                                <th>Organization Name</th>
                                                <th>User Name</th>
                                                <th>Ticket Type</th>
                                                <th>Message</th>
                                                <th>Date</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!isLoading && (isNoRecords || (Object.keys(tickets).length > 0 && tickets.pending.length === 0)) &&
                                                <tr className="text-center">
                                                    <td colSpan="7" className="fs-4">
                                                        No Records Found!
                                                    </td>
                                                </tr>
                                            }
                                            {!isNoRecords && !isLoading && (Object.keys(tickets).length > 0) && tickets.pending.length > 0 &&
                                                pendingItems.map((elem, index) => {
                                                    return (
                                                        <tr key={elem.id}>
                                                            <td>
                                                                <h5>{elem.ticket_no}</h5>
                                                            </td>
                                                            <td>
                                                                <h5>{elem.organization_name}</h5>
                                                            </td>
                                                            <td>
                                                                <h5>{elem.user_name}</h5>
                                                            </td>
                                                            <td>
                                                                <h5>{elem.title}</h5>
                                                            </td>
                                                            <td>
                                                                <h5>
                                                                {elem.support_text.split(" ").slice(0, 3).join(" ")}
                                                                {elem.support_text.split(" ").length > 3 ? "..." : ""}
                                                                </h5>

                                                            </td>
                                                            <td>
                                                                <h5>{moment(elem.updated_at).format('YYYY-MM-DD')}</h5>
                                                            </td>
                                                            <td className="text-center">
                                                                {/* <span className="badge badge_md">
                                                                <img src={docs_play} />
                                                            </span>
                                                            <span className="badge badge_md">
                                                                <img src={grn_excla} />
                                                            </span> */}

                                                                <Badge bg="brand_color2" className='text_brand_color1' onClick={() => openTicketModal(elem.ticket_no, 'resolve')} style={{ cursor: 'pointer' }}>Reply and <br />start resolving</Badge>&nbsp;|&nbsp;

                                                                <NavLink data-role="sub-admin" data-id={elem.id} onClick={() => openDeleteModal(elem.ticket_no)} >
                                                                    <span className="badge badge_md">
                                                                        <img src={rd_trash} />
                                                                    </span>
                                                                </NavLink>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                    {Object.keys(tickets).length > 0 && tickets.pending.length > 0 &&
                                        <PAGINATION allItems={tickets.pending} chunkedItems={pendingItems} handlePageClick={event => handlePageClick(event, 'pending')} pageCount={pendingPageCount} currentPageNumber={currentPageNum} initialPageNumber={initialPageNum} />
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="progress">
                                    <Table className="">
                                        <thead>
                                            <tr>
                                                <th>Support ID</th>
                                                <th>Organization Name</th>
                                                <th>User Name</th>
                                                <th>Ticket Type</th>
                                                <th>Message</th>
                                                <th>Date</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!isLoading && (isNoRecords || (Object.keys(tickets).length > 0 && tickets.progress.length === 0)) &&
                                                <tr className="text-center">
                                                    <td colSpan="7" className="fs-4">
                                                        No Records Found!
                                                    </td>
                                                </tr>
                                            }
                                            {!isNoRecords && !isLoading && (Object.keys(tickets).length > 0) && tickets.progress.length > 0 &&
                                                progressItems.map((elem, index) => {
                                                    return (
                                                        <tr key={elem.id}>
                                                            <td>
                                                                <h5>{elem.ticket_no}</h5>
                                                            </td>
                                                            <td>
                                                                <h5>{elem.organization_name}</h5>
                                                            </td>
                                                            <td>
                                                                <h5>{elem.user_name}</h5>
                                                            </td>
                                                            <td>
                                                                <h5>{elem.title}</h5>
                                                            </td>
                                                            <td>
                                                                <h5>
                                                                {elem.support_text.split(" ").slice(0, 3).join(" ")}
                                                                {elem.support_text.split(" ").length > 3 ? "..." : ""}
                                                                </h5>
                                                            </td>
                                                            <td>
                                                                <h5>{moment(elem.updated_at).format('YYYY-MM-DD')}</h5>
                                                            </td>
                                                            <td className="text-center">
                                                                {/* <span className="badge badge_md">
                                                                <img src={docs_play} />
                                                            </span>
                                                            <span className="badge badge_md">
                                                                <img src={grn_excla} />
                                                            </span> 
                                                                //  onClick={() => (navigate(`/chat/${elem?.id}`))} 

                                                            */}

                                                                {/* <Button variant='dark' size='sm' onClick={() => openMessageModal(elem.ticket_no, 'more')} >Request More Information
                                                                </Button>&nbsp; */}
                                                                <Button variant='dark' size='sm' onClick={() => openMessageModal(elem.ticket_no, 'reply')}>View
                                                                </Button>&nbsp;
                                                                {/* <Badge bg="brand_color2" className='text_brand_color1' onClick={() => openTicketModal(elem.ticket_no, 'close')} style={{ cursor: 'pointer' }}>Mark as Resolved</Badge>&nbsp;|&nbsp; */}

                                                                <NavLink data-role="sub-admin" data-id={elem.id} onClick={() => openDeleteModal(elem.ticket_no)} >
                                                                    <span className="badge badge_md">
                                                                        <img src={rd_trash} />
                                                                    </span>
                                                                </NavLink>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                    {Object.keys(tickets).length > 0 && tickets.progress.length > 0 &&
                                        <PAGINATION allItems={tickets.progress} chunkedItems={progressItems} handlePageClick={event => handlePageClick(event, 'progress')} pageCount={progressPageCount} currentPageNumber={currentPageNum} initialPageNumber={initialPageNum} />
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="resolved">
                                    <Table className="">
                                        <thead>
                                            <tr>
                                                <th>Support ID</th>
                                                <th>Organization Name</th>
                                                <th>User Name</th>
                                                <th>Ticket Type</th>
                                                <th>Message</th>
                                                <th>Date</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!isLoading && (isNoRecords || (Object.keys(tickets).length > 0 && tickets.resolved.length === 0)) &&
                                                <tr className="text-center">
                                                    <td colSpan="7" className="fs-4">
                                                        No Records Found!
                                                    </td>
                                                </tr>
                                            }
                                            {!isNoRecords && !isLoading && (Object.keys(tickets).length > 0) && tickets.resolved.length > 0 &&
                                                resolvedItems.map((elem, index) => {
                                                    return (
                                                        <tr key={elem.id}>
                                                            <td>
                                                                <h5>{elem.ticket_no}</h5>
                                                            </td>
                                                            <td>
                                                                <h5>{elem.organization_name}</h5>
                                                            </td>
                                                            <td>
                                                                <h5>{elem.user_name}</h5>
                                                            </td>
                                                            <td>
                                                                <h5>{elem.title}</h5>
                                                            </td>
                                                            <td>
                                                                <h5>
                                                                {elem.support_text.split(" ").slice(0, 3).join(" ")}
                                                                {elem.support_text.split(" ").length > 3 ? "..." : ""}
                                                                </h5>
                                                            </td>
                                                            <td>
                                                                <h5>{moment(elem.updated_at).format('YYYY-MM-DD')}</h5>
                                                            </td>
                                                            <td className="text-center">
                                                                {/* <span className="badge badge_md">
                                                                <img src={docs_play} />
                                                            </span>
                                                            <span className="badge badge_md">
                                                                <img src={grn_excla} />
                                                            </span> */}

                                                                {/* <Button variant='dark' size='sm' >View
                                                                </Button>&nbsp; */}
                                                                <NavLink data-role="sub-admin" data-id={elem.id} onClick={() => openDeleteModal(elem.ticket_no)} >
                                                                    <span className="badge badge_md">
                                                                        <img src={rd_trash} />
                                                                    </span>
                                                                </NavLink>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                    {Object.keys(tickets).length > 0 && tickets.resolved.length > 0 &&
                                        <PAGINATION allItems={tickets.resolved} chunkedItems={resolvedItems} handlePageClick={event => handlePageClick(event, 'resolved')} pageCount={resolvedPageCount} currentPageNumber={currentPageNum} initialPageNumber={initialPageNum} />
                                    }
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </Col>

                <Modal className="approve-modal" show={ticketModalShow} onHide={handleTicketModalClose} backdrop="static" keyboard={false} centered>
                    <Modal.Header className='justify-content-center'>
                    </Modal.Header>
                    <Modal.Body className='text-center'>
                        <h4>{modalText}</h4>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <div className="d-flex gap-3">
                            <Button variant="danger" onClick={handleTicketModalClose} >NO</Button>
                            <Button variant="primary" onClick={() => openMessageModal(null, 'reply')}>YES</Button>
                        </div>
                    </Modal.Footer>
                </Modal>

                <Modal className="approve-modal custom-modal" show={messageModalShow} onHide={handleMessageModalClose} centered>
                    {/* <Modal.Header closeButton className='justify-content-center'>
                        <div className='text-center'>
                            <Modal.Title className='fs-35 fw-700'>Support Chat</Modal.Title>
                            <Button variant='dark' size='sm'
                            //  onClick={() => openMessageModal(elem.ticket_no, 'more')} 
                             >Request More Information
                            </Button>&nbsp; 
                            <Badge bg="brand_color2" className='text_brand_color1'
                            //  onClick={() => openTicketModal(elem.ticket_no, 'close')} 
                             style={{ cursor: 'pointer' }}>Mark as Resolved</Badge>&nbsp;|&nbsp;
                        </div>
                    </Modal.Header> */}
                    {/* ticketId */}
                    <Modal.Header closeButton className="d-flex flex-column w-100" style={{ position: "relative" }}>
                    <Modal.Title className="fs-35 fw-700 w-100 text-center">Support Chat</Modal.Title>
                        <div className="d-flex justify-content-end gap-2 w-100 mt-3">
                            <Button variant="dark" size="sm" onClick={() => changeStatus('more')}>Request More Information</Button>
                            <Badge bg="brand_color2" className="text_brand_color1"
                            onClick={() => changeStatus('resolved')}
                             style={{ cursor: "pointer" }}>
                            Mark as Resolved
                            </Badge>
                        </div>
                    </Modal.Header>
                    <Form onSubmit={handleSubmit(changeStatus)}>

                    <Modal.Body>
                        {/* Chat Box with Scroll */}
                        <div
                            className="chat-box"
                            style={{
                            maxHeight: "400px",
                            overflowY: "auto",
                            padding: "10px",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            }}
                            ref={chatBoxRef}
                        >
                            {messages.map((msg, index) => (
                          
                            <div key ={index} style={{ display: "flex", flexDirection: "column", alignItems: msg.message_by === "admin" ? "flex-end" : "flex-start" }}>
                            <p
                              style={{
                                backgroundColor: msg.message_by === "admin" ? "#007bff" : "#f1f1f1",
                                color: msg.message_by === "admin" ? "#fff" : "#000",
                                padding: "6px 10px",
                                borderRadius: "10px",
                                display: "inline-block",
                                margin: "3px 0",
                                maxWidth: "70%",
                                wordWrap: "break-word",
                                fontSize: "14px", // Small text
                                position: "relative",
                              }}
                            >
                              {msg.message}
                              <span
                                style={{
                                  fontSize: "10px", // Small timestamp
                                  color: msg.message_by === "admin" ? "#cce5ff" : "#888",
                                  display: "block",
                                  textAlign: "right",
                                  marginTop: "3px",
                                }}
                              >
                                {`${msg.contact_date} ${msg.contact_month} ${msg.chat_time}`}
                              </span>
                            </p>
                          </div>
                          

                            ))}
                        </div>

                        {/* Input & Button in One Row */}
                        {!moreInfoMessage && (
                            <>
                        <Form.Group className="mb-3">
                            <Form.Label>
                            Message<span className="text-danger">*</span>
                            </Form.Label>
                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                           
                                <Form.Control
                                as="textarea"
                                name="message"
                                {...register("message", { required: "This field is required" })}
                                autoComplete="off"
                                style={{
                                    height: "60px",
                                    resize: "none",
                                    flex: 1, // Makes input take available space
                                }}
                                />
                                <Button variant="primary" type="submit">
                                Submit
                                </Button>
                            </div>

                            {errors.message?.message && (
                            <span className="error">{errors.message?.message}</span>
                            )}
                        </Form.Group>
                        </>
                            )}
                        </Modal.Body>

                        {/* <Modal.Body>
                            <div className="chat-box" style={{ maxHeight: "300px", overflowY: "auto", padding: "10px", border: "1px solid #ccc", borderRadius: "5px" }}>
                            {messages.map((msg, index) => (
                                <div key={index} style={{ textAlign: msg.message_by === "user" ? "left" : "right" }}>
                                <p
                                style={{ backgroundColor: msg.message_by === "user" ? "#007bff" : "#f1f1f1", color: msg.message_by === "user" ? "#fff" : "#000", padding: "8px 12px", borderRadius: "10px", display: "inline-block", margin: "5px 0" }}>
                                    {msg.message}
                                </p>
                                </div>
                            ))}
                            </div>
                            <Form.Group className="mb-3">
                                <Form.Label>Message<span className='text-danger'>*</span></Form.Label>
                                <Form.Control as="textarea" name="message" {...register('message', { required: "This field is required" })} autoComplete="off" />

                                {errors.message?.message && <span className="error">{errors.message?.message}</span>}
                            </Form.Group>
                        </Modal.Body> */}
                        
                    </Form>
                </Modal>

                <Modal className="approve-modal" show={deleteModalShow} onHide={handleDeleteModalClose} backdrop="static" keyboard={false} centered>
                    <Modal.Header className='justify-content-center'>
                    </Modal.Header>
                    <Modal.Body className='text-center'>
                        <h4>Are you sure you want to delete ?</h4>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <div className="d-flex gap-3">
                            <Button variant="danger" onClick={handleDeleteModalClose} >NO</Button>
                            <Button variant="primary" onClick={() => deleteTicket()}>YES</Button>
                        </div>
                    </Modal.Footer>
                </Modal>

            </Row>
        </>
    )
}

export default Tickets;